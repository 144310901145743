import {AbStorage} from "app-to-up-vue-renderer/src/renderer/utils/abStorage";

export const getConfig = async (fileName = "app-settings") => {

    // Create storage
    const storage = new AbStorage({
        load: async () => {
            try {
                return JSON.parse(localStorage.getItem(fileName, "{}"))
            } catch (ex) {
                return {}
            }
        },
        save: (saveData) => {
            // Save to local storage
            localStorage.setItem(fileName, JSON.stringify(saveData))
        }
    });

    // Load storage
    await storage.loadStorage()

    // Return storage
    return storage
}
